<template>
  <div>
    <!-- 电脑版banner -->
    <div class="business_banner u-rela">
      <picture>
        <source media="(min-width: 992px)" srcset="../images/service_banner3.jpg">
        <img src=""/>
      </picture>
      <div class="u-abso business_banner_main">
        <div class="u-flex business_banner_con">
          <div class="banner_left u-flex-1 color-474747 u-flex">
            <div class="sloganDiv">
              <div class="slogancon" id="business_banner_con">
                <p class="slogan">通过八爪灵工平台，</p>
                <div class="mb-10"></div>
                <p class="slogan">深度发掘个人价值。</p>
              </div>
              <span class="sloganbutton color-ffffff curspointer" @click="toTask">浏览任务</span>
            </div>
          </div>
          <div class="banner_right">
            <picture>
              <source media="(min-width: 992px)" srcset="../images/service_banner_right3.png">
              <img src=""/>
            </picture>
          </div>
        </div>
      </div>
      <div class="navtab u-flex">
        <div class="u-flex-1 u-p-t-15 u-p-b-15 u-flex u-row-center font-300 u-font-22"><a href="/business">如何聘请自由职业者</a>
        </div>
        <div class="u-flex-1 u-p-t-15 u-p-b-15 u-flex u-row-center font-300 u-font-22 active"><a href="/freebusiness">自由职业者如何赚钱</a>
        </div>
      </div>
    </div>
    <!-- end 电脑版banner -->
    <!-- 手机版banner -->
    <div class="m_business_banner u-rela">
      <picture>
        <source media="(max-width: 992px)" srcset="../images/m_business_banner2.jpg">
        <img src=""/>
      </picture>
      <div class="u-abso banner_main">
        <div class="banner_left u-flex-1 color-474747 u-flex">
          <div>
            <div class="slogancon">
              <p class="slogan">通过八爪灵工平台，</p>
              <p class="slogan">深度发掘个人价值。</p>
            </div>
            <span class="sloganbutton color-ffffff curspointer">发布任务</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end手机版banner -->

    <!-- 手机端tab切换 -->
    <div class="mobiletab">
      <div class="navtab u-flex">
        <div class="u-flex-1 u-p-t-15 u-p-b-15 u-flex u-row-center font-300 u-font-22 active"><a href="/business">如何聘请自由职业者</a>
        </div>
        <div class="u-flex-1 u-p-t-15 u-p-b-15 u-flex u-row-center font-300 u-font-22"><a href="/free/business">自由职业者如何赚钱</a>
        </div>
      </div>
    </div>
    <!-- end手机端tab切换 -->


    <!-- 企业服务2 -->
    <div class="bz_wrapper_content">

      <div class="bz_qybz u-flex  u-p-t-30  u-p-b-30 clearfix">
        <div class="u-flex-1 u-flex u-m-b-40">
          <div>
            <div class="u-font-40 font_blod_700 service_title color-474747">
              如何成为自由职业者
              <p class="small-title u-font-16 color-474747 lin1_5 u-m-t-6">
                自由职业者将完全按照您的意愿完成您的工作要求，把您的想法变成现实。
              </p>
            </div>

            <div class="u-flex u-col-top u-m-b-25">
              <div>
                <Isvg icon-class="home-icon6" class-name="home-icon"/>
              </div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">完善简历</p>
                <p class="u-font-16 color-474747 lin1_5">完善个人主页相关内容，包括学历、技能等级、工作经验等。</p>
              </div>
            </div>

            <div class="u-flex u-col-top u-m-b-25">
              <div>
                <Isvg icon-class="home-icon3" class-name="home-icon"/>
              </div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">人岗精准匹配</p>
                <div class="pcshow">
                  <p class="u-font-16 color-474747 lin1_5">根据您的简历信息，系统经过大数据算法精准匹配更适合您的工作，
                  </p>
                  <p class="u-font-16 color-474747 lin1_5">同时您也可以检索自己感兴趣的工作。</p>
                </div>
                <div class="mobileshow">
                  <p class="u-font-16 color-474747 lin1_5">根据您的简历信息，系统经过大数据算法精准匹配更适合您的工作，同时您也可以检索自己感兴趣的工作。
                  </p>
                </div>

              </div>
            </div>

            <div class="u-flex u-col-top u-m-b-25">
              <div>
                <Isvg icon-class="home-icon7" class-name="home-icon"/>
              </div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">定位自己的工时</p>
                <div class="pcshow">
                  <p class="u-font-16 color-474747 lin1_5">尽可能地准确定位自己的工时或项目价格，</p>
                  <p class="u-font-16 color-474747 lin1_5">以便企业更好地了解您的价值，尽早找到适合自己的项目。</p>
                </div>
                <div class="mobileshow">
                  <p class="u-font-16 color-474747 lin1_5">尽可能地准确定位自己的工时或项目价格，以便企业更好地了解您的价值，尽早找到适合自己的项目。</p>
                </div>
              </div>
            </div>

            <div class="u-flex u-col-top u-m-b-25">
              <div>
                <Isvg icon-class="home-icon8" class-name="home-icon"/>
              </div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">获得报酬</p>
                <p class="u-font-16 color-474747 lin1_5">通过八爪灵工平台，更好地实现个人价值。</p>
              </div>
            </div>

          </div>
        </div>
        <div class="u-flex-1 flex-con-img">
          <div class="u-text-center">
            <img src="../images/business4.png"/>
          </div>
        </div>
      </div>

      <div class="bz_zyzj u-flex u-p-t-30  clearfix">
        <div class="u-flex-1 flex-con-img">
          <div class="u-text-center">
            <img src="../images/business5.png"/>
          </div>
        </div>
        <div class="u-flex-1 u-flex u-m-b-40">
          <div>
            <p class="u-font-40 font_blod_700 service_title color-474747">
              合作从沟通开始
            </p>
            <div class="u-flex u-col-top u-m-b-25">
              <div><img src="../images/icon5.png" width="50" class="iconimg"/></div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">在线沟通</p>
                <div class="pcshow">
                  <p class="u-font-16 color-474747 lin1_5">与满足您要求的自由职业者快速沟通，交流想法</p>
                  <p class="u-font-16 color-474747 lin1_5">以便更好地完成任务。</p>
                </div>
                <div class="mobileshow">
                  <p class="u-font-16 color-474747 lin1_5">与满足您要求的自由职业者快速沟通，交流想法,以便更好地完成任务。</p>
                </div>

              </div>
            </div>
            <div class="u-flex flex-start">
              <div><img src="../images/ico6.png" width="50" class="iconimg"/></div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">移动应用</p>
                <div class="pcshow">
                  <p class="u-font-16 color-474747 lin1_5">与满足您要求的自由职业者快速沟通，交流想法</p>
                  <p class="u-font-16 color-474747 lin1_5">在微信小程序端，轻触指尖达成合作。</p>
                </div>
                <div class="mobileshow">
                  <p class="u-font-16 color-474747 lin1_5">与满足您要求的自由职业者快速沟通，交流想法,在微信小程序端，轻触指尖达成合作。</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="bz_qybz u-flex  u-p-t-30  u-p-b-30 clearfix">
        <div class="u-flex-1 u-flex u-m-b-40">
          <div>
            <p class="u-font-40 font_blod_700 service_title color-474747">
              安全可靠的服务
            </p>
            <div class="u-flex u-col-top u-m-b-25">
              <div><img src="../images/icon9.png" width="50" class="iconimg"/></div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">款项的发放</p>
                <p class="u-font-16 color-474747 lin1_5">平台的资金可通过支付宝或银行卡支付，更加安全。</p>
              </div>
            </div>
            <div class="u-flex flex-start">
              <div><img src="../images/icon10.png" width="50" class="iconimg"/></div>
              <div class="u-p-l-10">
                <p class="u-font-20 font_blod_700 color-474747 u-m-b-10">客服的保障</p>
                <p class="u-font-16 color-474747 lin1_5">7*24小时的客服在线，保障每一位用户的合法权益。</p>

              </div>
            </div>

          </div>
        </div>
        <div class="u-flex-1 flex-con-img">
          <div class="u-text-center">
            <img src="../images/business6.png"/>
          </div>
        </div>
      </div>

    </div>
    <!-- end 企业服务2 -->


    <!-- 发布任务 -->
    <div class="commonad bg-00308c">
      <div class="bz_wrapper_content">
        <div class="u-flex u-p-t-48 u-p-b-48 commonadcontent2">
          <div class="u-flex-1 commonadcontentlist2">
            <p class="u-font-30 font_blod_300 color-ffffff u-p-b-10">想要找到更好的项目？</p>
            <p class="u-font-18 color-ffffff u-p-t-18">快来填写个人简介、完善个人主页并为自己的服务精准定价吧！</p>
          </div>
          <div>
            <span class="sloganbutton color-ffffff curspointer" @click="toTask">浏览任务</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end发布任务 -->

    <!-- 成功案例 -->
    <div class="bz_wrapper_content">
      <div class="u-font-40 font_blod_700 service_title color-474747">自由职业者们的成功案例</div>

      <div class="casecontainer">
        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg1.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                Logo设计
              </p>
              <p class="u-font-16">
                30天500元
              </p>
            </div>
          </div>
        </div>

        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg2.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                网站设计
              </p>
              <p class="u-font-16">
                30天10000元
              </p>
            </div>
          </div>
        </div>

        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg3.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                法律咨询服务
              </p>
              <p class="u-font-16">
                30天 16800元
              </p>
            </div>
          </div>
        </div>

        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg4.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                人力资源管理业务咨询
              </p>
              <p class="u-font-16">
                3天 500元
              </p>
            </div>
          </div>
        </div>

        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg5.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                PHP程序制作
              </p>
              <p class="u-font-16">
                30天 23000元
              </p>
            </div>
          </div>
        </div>

        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg6.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                小程序开发
              </p>
              <p class="u-font-16">
                30天 11000元
              </p>
            </div>
          </div>
        </div>

        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg7.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                法律咨询服务
              </p>
              <p class="u-font-16">
                3小时500元
              </p>
            </div>
          </div>
        </div>

        <div class="caselist">
          <div class="listcontainer">
            <img src="../images/caseimg8.jpg"/>
            <div class="text">
              <p class="u-font-18 font_blod_700">
                人力资源管理业务咨询
              </p>
              <p class="u-font-16">
                3天 500元
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="u-flex u-row-center u-m-t-30 u-m-b-30 lookmore"><span class="sloganbutton color-ffffff curspointer"
                                                                        @click="toService">查看更多</span></div>
    </div>
    <!-- end成功案例 -->

  </div>
</template>

<script>
export default {
  methods: {
    toTask() {
      this.$router.push({path: "/task"})
    },
    toService() {
      this.$router.push({path: "/service"})
    }
  }
}
</script>

<style>
@charset "UTF-8";
.curspointer {
  cursor: pointer;
}

.svg-icon.home-icon {
  width: 50px;
  height: 50px;
}

.color-000000 {
  color: #000000;
}

.color-ffffff {
  color: #ffffff;
}

.color-00308c {
  color: #00308c;
}

.color-898fa4 {
  color: #898fa4;
}

.color-030f3b {
  color: #030f3b;
}

.color-0d5eb2 {
  color: #0d5eb2;
}

.color-474747 {
  color: #474747;
}

color-d6d7d9 {
  color: #d6d7d9;
}

color-f7f7f7 {
  color: #f7f7f7;
}

.bg-ffffff {
  background-color: #ffffff;
}

.bg-00308c {
  background-color: #00308c;
}

.bg-898fa4 {
  background-color: #898fa4;
}

.bg-030f3b {
  background-color: #030f3b;
}

.bg-f7f7f7 {
  background-color: #f7f7f7;
}

.border-000000 {
  color: #000000;
}

.border-ffffff {
  border-color: #ffffff;
}

.border-00308c {
  border-color: #00308c;
}

.border-898fa4 {
  border-color: #898fa4;
}

.border-030f3b {
  border-color: #030f3b;
}

.bz_header_wrapper {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
}

.bz_header_hoder {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 0px;
  visibility: hidden;
}

.bz_header_hoder img {
  height: 36px;
}

.bz_wrapper {
  width: 8.9583333333rem;
  margin: 0px auto;
}

.bz_header {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logo {
  width: 200px;
  font-size: 0px;
}

.logo img {
  height: 36px;
}

.nav .navtitle {
  padding: 0px 12px;
  margin-right: 16px;
}

.nav .navtitle a {
  color: #474747;
}

.nav .navtitle:hover, .nav .navtitle a:hover {
  color: #0d5eb2;
}

.header-right {
  padding-right: 20px;
  color: #030f3b;
}

.header-right a {
  color: #030f3b;
}

.header-right .button {
  padding: 6px 20px;
}

.header-right .button:hover {
  text-decoration: underline;
}

.header-right .button.active {
  color: #f7f7f7;
  border-color: #030f3b;
  background: #030f3b;
  border-radius: 7px;
}

.header-right .button.active:hover {
  text-decoration: none;
}

.header-right .button.active a {
  color: #ffffff;
}

.bz_header .navbar-toggle {
  border: 0px;
  background: none;
  display: none;
}

.bz_footer {
  background: #00308c;
  padding: 38px;
  line-height: 2;
  letter-spacing: 1px;
}

.footertitle {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
}

.footersubtitle {
  font-size: 14px;
}

.footertitle span {
  margin: 0 2px;
}

.footertitle span:nth-of-type(1) {
  margin: 0px 0px 2px 0px;
}

.footerpad-r-70 {
  padding-right: 0.3645833333rem;
}

.footerpad-r-70::after {
  content: '';
  width: 1px;
  border-left: 1px solid #f7f7f7;
  position: absolute;
  right: 0.1822916667rem;
  top: 0.0520833333rem;
  height: 1.0416666667rem;
}

.contact {
  text-align: center;
  margin: 0px 20px 0px 0px;
}

.contactusform {
  width: 80%;
  margin-left: 3%;
}

@media screen and (max-width: 1100px) {
  .bz_footer .bz_wrapper {
    width: 100%;
    flex-wrap: wrap;
  }

  .bz_footer {
    padding: 20px;
  }

  .bz_footer .footertitle {
    display: flex;
    flex-wrap: wrap;
  }

  .bz_footer .footertitle span:nth-of-type(1) {
    margin: 0px;
  }

  .bz_footer .footertitle span {
    margin: 0px;
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 992px) {
  @keyframes header-fade-up {
    0% {
      transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
    }
    100% {
      transform: translate(0);
      -webkit-transform: translate(0);
      transition: transform 0.3s ease-out;
      -webkit-transition: transform 0.3s ease-out;
    }
  }
  @-webkit-keyframes header-fade-up {
    0% {
      transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
    }
    100% {
      transform: translate(0);
      -webkit-transform: translate(0);
      transition: transform 0.3s ease-out;
      -webkit-transition: transform 0.3s ease-out;
    }
  }
  .bz_header .navbar-toggle {
    display: block;
  }

  .bz_header .header-right {
    padding-right: 0px;
  }

  .bz_header_wrapper {
    background-color: white;
    box-shadow: 0 1px 3px #cdcdcd;
  }

  .bz_header_hoder {
    background-color: white;
  }

  .bz_header_hoder {
    background: transparent;
  }

  #navbarcontent {
    padding-bottom: 30px;
    margin-top: 30px;
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-grow: 1;
    flex-direction: column;
    -ms-flex-direction: column;
    animation: header-fade-up 0.3s ease-out;
    -webkit-animation: header-fade-up .3s;
  }

  #navbarcontent .nav {
    flex-direction: column;
    -ms-flex-direction: column;
    width: 100%;
    padding: 0px 40px;
  }

  #navbarcontent .nav .navtitle {
    width: 100%;
    margin: 0px;
    padding: 15px 0px;
    text-align: center;
    border-bottom: 1px dotted #cdcdcd;
  }

  #navbarcontent .header-right {
    flex-direction: column;
    -ms-flex-direction: column;
    display: flex;
  }

  #navbarcontent .header-right .button {
    margin: 5px 0px;
  }

  /*#navbarcontent:not(.show) {*/
  /*  display: none; }*/
  .bz_footer .footertitle span {
    margin: 0px;
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 768px) {
  .bz_footer .footerleft, .bz_footer .footeright {
    flex: 0 0 100%;
  }

  .bz_footer .footertitle span {
    flex: 0px;
  }

  .footerpad-r-70::after {
    border-left: 0px;
  }
}

@media screen and (max-width: 576px) {
  .bz_footer .footertitle span {
    flex: 0 0 100%;
  }

  .bz_footer .footerleft, .bz_footer .footeright .footerpad-r-70 {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .bz_footer .bz_wrapper {
    position: relative;
  }

  .bz_footer .footerleft::before, .bz_footer .footeright .footerpad-r-70::before {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: "";
    display: inline-block;
    border-bottom: solid 1px #ffffff;
    height: 1px;
    width: 100%;
    transform: scaleY(0.5);
  }

  .bz_footer .footertitle span::before {
    content: "";
    width: 10px;
    height: 10px;
    border: solid 1px #ffffff;
    display: inline-block;
    border-top: 0px;
    border-right: 0px;
    transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
  }
}

@media screen and (max-width: 416px) {
  .bz_header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logo img {
    height: 28px;
  }

  .bz_header_hoder img {
    height: 28px;
  }

  .footerpad-r-70 {
    padding-right: 0px;
  }
}

.u-font-9 {
  font-size: 9px;
}

.u-font-10 {
  font-size: 10px;
}

.u-font-11 {
  font-size: 11px;
}

.u-font-12 {
  font-size: 12px;
}

.u-font-13 {
  font-size: 13px;
}

.u-font-14 {
  font-size: 14px;
}

.u-font-15 {
  font-size: 15px;
}

.u-font-16 {
  font-size: 16px;
}

.u-font-17 {
  font-size: 17px;
}

.u-font-18 {
  font-size: 18px;
}

.u-font-19 {
  font-size: 19px;
}

.u-font-20 {
  font-size: 20px;
}

.u-font-21 {
  font-size: 21px;
}

.u-font-22 {
  font-size: 22px;
}

.u-font-23 {
  font-size: 23px;
}

.u-font-24 {
  font-size: 24px;
}

.u-font-25 {
  font-size: 25px;
}

.u-font-26 {
  font-size: 26px;
}

.u-font-27 {
  font-size: 27px;
}

.u-font-28 {
  font-size: 28px;
}

.u-font-29 {
  font-size: 29px;
}

.u-font-30 {
  font-size: 30px;
}

.u-font-31 {
  font-size: 31px;
}

.u-font-32 {
  font-size: 32px;
}

.u-font-33 {
  font-size: 33px;
}

.u-font-34 {
  font-size: 34px;
}

.u-font-35 {
  font-size: 35px;
}

.u-font-36 {
  font-size: 36px;
}

.u-font-37 {
  font-size: 37px;
}

.u-font-38 {
  font-size: 38px;
}

.u-font-39 {
  font-size: 39px;
}

.u-font-40 {
  font-size: 40px;
}

.u-font-41 {
  font-size: 41px;
}

.u-font-42 {
  font-size: 42px;
}

.u-font-43 {
  font-size: 43px;
}

.u-font-44 {
  font-size: 44px;
}

.u-font-45 {
  font-size: 45px;
}

.u-font-46 {
  font-size: 46px;
}

.u-font-47 {
  font-size: 47px;
}

.u-font-48 {
  font-size: 48px;
}

.u-font-49 {
  font-size: 49px;
}

.u-margin-0, .u-m-0 {
  margin: 0px !important;
}

.u-padding-0, .u-p-0 {
  padding: 0px !important;
}

.u-m-l-0 {
  margin-left: 0px !important;
}

.u-p-l-0 {
  padding-left: 0px !important;
}

.u-margin-left-0 {
  margin-left: 0px !important;
}

.u-padding-left-0 {
  padding-left: 0px !important;
}

.u-m-t-0 {
  margin-top: 0px !important;
}

.u-p-t-0 {
  padding-top: 0px !important;
}

.u-margin-top-0 {
  margin-top: 0px !important;
}

.u-padding-top-0 {
  padding-top: 0px !important;
}

.u-m-r-0 {
  margin-right: 0px !important;
}

.u-p-r-0 {
  padding-right: 0px !important;
}

.u-margin-right-0 {
  margin-right: 0px !important;
}

.u-padding-right-0 {
  padding-right: 0px !important;
}

.u-m-b-0 {
  margin-bottom: 0px !important;
}

.u-p-b-0 {
  padding-bottom: 0px !important;
}

.u-margin-bottom-0 {
  margin-bottom: 0px !important;
}

.u-padding-bottom-0 {
  padding-bottom: 0px !important;
}

.u-margin-2, .u-m-2 {
  margin: 2px !important;
}

.u-padding-2, .u-p-2 {
  padding: 2px !important;
}

.u-m-l-2 {
  margin-left: 2px !important;
}

.u-p-l-2 {
  padding-left: 2px !important;
}

.u-margin-left-2 {
  margin-left: 2px !important;
}

.u-padding-left-2 {
  padding-left: 2px !important;
}

.u-m-t-2 {
  margin-top: 2px !important;
}

.u-p-t-2 {
  padding-top: 2px !important;
}

.u-margin-top-2 {
  margin-top: 2px !important;
}

.u-padding-top-2 {
  padding-top: 2px !important;
}

.u-m-r-2 {
  margin-right: 2px !important;
}

.u-p-r-2 {
  padding-right: 2px !important;
}

.u-margin-right-2 {
  margin-right: 2px !important;
}

.u-padding-right-2 {
  padding-right: 2px !important;
}

.u-m-b-2 {
  margin-bottom: 2px !important;
}

.u-p-b-2 {
  padding-bottom: 2px !important;
}

.u-margin-bottom-2 {
  margin-bottom: 2px !important;
}

.u-padding-bottom-2 {
  padding-bottom: 2px !important;
}

.u-margin-4, .u-m-4 {
  margin: 4px !important;
}

.u-padding-4, .u-p-4 {
  padding: 4px !important;
}

.u-m-l-4 {
  margin-left: 4px !important;
}

.u-p-l-4 {
  padding-left: 4px !important;
}

.u-margin-left-4 {
  margin-left: 4px !important;
}

.u-padding-left-4 {
  padding-left: 4px !important;
}

.u-m-t-4 {
  margin-top: 4px !important;
}

.u-p-t-4 {
  padding-top: 4px !important;
}

.u-margin-top-4 {
  margin-top: 4px !important;
}

.u-padding-top-4 {
  padding-top: 4px !important;
}

.u-m-r-4 {
  margin-right: 4px !important;
}

.u-p-r-4 {
  padding-right: 4px !important;
}

.u-margin-right-4 {
  margin-right: 4px !important;
}

.u-padding-right-4 {
  padding-right: 4px !important;
}

.u-m-b-4 {
  margin-bottom: 4px !important;
}

.u-p-b-4 {
  padding-bottom: 4px !important;
}

.u-margin-bottom-4 {
  margin-bottom: 4px !important;
}

.u-padding-bottom-4 {
  padding-bottom: 4px !important;
}

.u-margin-5, .u-m-5 {
  margin: 5px !important;
}

.u-padding-5, .u-p-5 {
  padding: 5px !important;
}

.u-m-l-5 {
  margin-left: 5px !important;
}

.u-p-l-5 {
  padding-left: 5px !important;
}

.u-margin-left-5 {
  margin-left: 5px !important;
}

.u-padding-left-5 {
  padding-left: 5px !important;
}

.u-m-t-5 {
  margin-top: 5px !important;
}

.u-p-t-5 {
  padding-top: 5px !important;
}

.u-margin-top-5 {
  margin-top: 5px !important;
}

.u-padding-top-5 {
  padding-top: 5px !important;
}

.u-m-r-5 {
  margin-right: 5px !important;
}

.u-p-r-5 {
  padding-right: 5px !important;
}

.u-margin-right-5 {
  margin-right: 5px !important;
}

.u-padding-right-5 {
  padding-right: 5px !important;
}

.u-m-b-5 {
  margin-bottom: 5px !important;
}

.u-p-b-5 {
  padding-bottom: 5px !important;
}

.u-margin-bottom-5 {
  margin-bottom: 5px !important;
}

.u-padding-bottom-5 {
  padding-bottom: 5px !important;
}

.u-margin-6, .u-m-6 {
  margin: 6px !important;
}

.u-padding-6, .u-p-6 {
  padding: 6px !important;
}

.u-m-l-6 {
  margin-left: 6px !important;
}

.u-p-l-6 {
  padding-left: 6px !important;
}

.u-margin-left-6 {
  margin-left: 6px !important;
}

.u-padding-left-6 {
  padding-left: 6px !important;
}

.u-m-t-6 {
  margin-top: 6px !important;
}

.u-p-t-6 {
  padding-top: 6px !important;
}

.u-margin-top-6 {
  margin-top: 6px !important;
}

.u-padding-top-6 {
  padding-top: 6px !important;
}

.u-m-r-6 {
  margin-right: 6px !important;
}

.u-p-r-6 {
  padding-right: 6px !important;
}

.u-margin-right-6 {
  margin-right: 6px !important;
}

.u-padding-right-6 {
  padding-right: 6px !important;
}

.u-m-b-6 {
  margin-bottom: 6px !important;
}

.u-p-b-6 {
  padding-bottom: 6px !important;
}

.u-margin-bottom-6 {
  margin-bottom: 6px !important;
}

.u-padding-bottom-6 {
  padding-bottom: 6px !important;
}

.u-margin-8, .u-m-8 {
  margin: 8px !important;
}

.u-padding-8, .u-p-8 {
  padding: 8px !important;
}

.u-m-l-8 {
  margin-left: 8px !important;
}

.u-p-l-8 {
  padding-left: 8px !important;
}

.u-margin-left-8 {
  margin-left: 8px !important;
}

.u-padding-left-8 {
  padding-left: 8px !important;
}

.u-m-t-8 {
  margin-top: 8px !important;
}

.u-p-t-8 {
  padding-top: 8px !important;
}

.u-margin-top-8 {
  margin-top: 8px !important;
}

.u-padding-top-8 {
  padding-top: 8px !important;
}

.u-m-r-8 {
  margin-right: 8px !important;
}

.u-p-r-8 {
  padding-right: 8px !important;
}

.u-margin-right-8 {
  margin-right: 8px !important;
}

.u-padding-right-8 {
  padding-right: 8px !important;
}

.u-m-b-8 {
  margin-bottom: 8px !important;
}

.u-p-b-8 {
  padding-bottom: 8px !important;
}

.u-margin-bottom-8 {
  margin-bottom: 8px !important;
}

.u-padding-bottom-8 {
  padding-bottom: 8px !important;
}

.u-margin-10, .u-m-10 {
  margin: 10px !important;
}

.u-padding-10, .u-p-10 {
  padding: 10px !important;
}

.u-m-l-10 {
  margin-left: 10px !important;
}

.u-p-l-10 {
  padding-left: 10px !important;
}

.u-margin-left-10 {
  margin-left: 10px !important;
}

.u-padding-left-10 {
  padding-left: 10px !important;
}

.u-m-t-10 {
  margin-top: 10px !important;
}

.u-p-t-10 {
  padding-top: 10px !important;
}

.u-margin-top-10 {
  margin-top: 10px !important;
}

.u-padding-top-10 {
  padding-top: 10px !important;
}

.u-m-r-10 {
  margin-right: 10px !important;
}

.u-p-r-10 {
  padding-right: 10px !important;
}

.u-margin-right-10 {
  margin-right: 10px !important;
}

.u-padding-right-10 {
  padding-right: 10px !important;
}

.u-m-b-10 {
  margin-bottom: 10px !important;
}

.u-p-b-10 {
  padding-bottom: 10px !important;
}

.u-margin-bottom-10 {
  margin-bottom: 10px !important;
}

.u-padding-bottom-10 {
  padding-bottom: 10px !important;
}

.u-margin-12, .u-m-12 {
  margin: 12px !important;
}

.u-padding-12, .u-p-12 {
  padding: 12px !important;
}

.u-m-l-12 {
  margin-left: 12px !important;
}

.u-p-l-12 {
  padding-left: 12px !important;
}

.u-margin-left-12 {
  margin-left: 12px !important;
}

.u-padding-left-12 {
  padding-left: 12px !important;
}

.u-m-t-12 {
  margin-top: 12px !important;
}

.u-p-t-12 {
  padding-top: 12px !important;
}

.u-margin-top-12 {
  margin-top: 12px !important;
}

.u-padding-top-12 {
  padding-top: 12px !important;
}

.u-m-r-12 {
  margin-right: 12px !important;
}

.u-p-r-12 {
  padding-right: 12px !important;
}

.u-margin-right-12 {
  margin-right: 12px !important;
}

.u-padding-right-12 {
  padding-right: 12px !important;
}

.u-m-b-12 {
  margin-bottom: 12px !important;
}

.u-p-b-12 {
  padding-bottom: 12px !important;
}

.u-margin-bottom-12 {
  margin-bottom: 12px !important;
}

.u-padding-bottom-12 {
  padding-bottom: 12px !important;
}

.u-margin-14, .u-m-14 {
  margin: 14px !important;
}

.u-padding-14, .u-p-14 {
  padding: 14px !important;
}

.u-m-l-14 {
  margin-left: 14px !important;
}

.u-p-l-14 {
  padding-left: 14px !important;
}

.u-margin-left-14 {
  margin-left: 14px !important;
}

.u-padding-left-14 {
  padding-left: 14px !important;
}

.u-m-t-14 {
  margin-top: 14px !important;
}

.u-p-t-14 {
  padding-top: 14px !important;
}

.u-margin-top-14 {
  margin-top: 14px !important;
}

.u-padding-top-14 {
  padding-top: 14px !important;
}

.u-m-r-14 {
  margin-right: 14px !important;
}

.u-p-r-14 {
  padding-right: 14px !important;
}

.u-margin-right-14 {
  margin-right: 14px !important;
}

.u-padding-right-14 {
  padding-right: 14px !important;
}

.u-m-b-14 {
  margin-bottom: 14px !important;
}

.u-p-b-14 {
  padding-bottom: 14px !important;
}

.u-margin-bottom-14 {
  margin-bottom: 14px !important;
}

.u-padding-bottom-14 {
  padding-bottom: 14px !important;
}

.u-margin-15, .u-m-15 {
  margin: 15px !important;
}

.u-padding-15, .u-p-15 {
  padding: 15px !important;
}

.u-m-l-15 {
  margin-left: 15px !important;
}

.u-p-l-15 {
  padding-left: 15px !important;
}

.u-margin-left-15 {
  margin-left: 15px !important;
}

.u-padding-left-15 {
  padding-left: 15px !important;
}

.u-m-t-15 {
  margin-top: 15px !important;
}

.u-p-t-15 {
  padding-top: 15px !important;
}

.u-margin-top-15 {
  margin-top: 15px !important;
}

.u-padding-top-15 {
  padding-top: 15px !important;
}

.u-m-r-15 {
  margin-right: 15px !important;
}

.u-p-r-15 {
  padding-right: 15px !important;
}

.u-margin-right-15 {
  margin-right: 15px !important;
}

.u-padding-right-15 {
  padding-right: 15px !important;
}

.u-m-b-15 {
  margin-bottom: 15px !important;
}

.u-p-b-15 {
  padding-bottom: 15px !important;
}

.u-margin-bottom-15 {
  margin-bottom: 15px !important;
}

.u-padding-bottom-15 {
  padding-bottom: 15px !important;
}

.u-margin-16, .u-m-16 {
  margin: 16px !important;
}

.u-padding-16, .u-p-16 {
  padding: 16px !important;
}

.u-m-l-16 {
  margin-left: 16px !important;
}

.u-p-l-16 {
  padding-left: 16px !important;
}

.u-margin-left-16 {
  margin-left: 16px !important;
}

.u-padding-left-16 {
  padding-left: 16px !important;
}

.u-m-t-16 {
  margin-top: 16px !important;
}

.u-p-t-16 {
  padding-top: 16px !important;
}

.u-margin-top-16 {
  margin-top: 16px !important;
}

.u-padding-top-16 {
  padding-top: 16px !important;
}

.u-m-r-16 {
  margin-right: 16px !important;
}

.u-p-r-16 {
  padding-right: 16px !important;
}

.u-margin-right-16 {
  margin-right: 16px !important;
}

.u-padding-right-16 {
  padding-right: 16px !important;
}

.u-m-b-16 {
  margin-bottom: 16px !important;
}

.u-p-b-16 {
  padding-bottom: 16px !important;
}

.u-margin-bottom-16 {
  margin-bottom: 16px !important;
}

.u-padding-bottom-16 {
  padding-bottom: 16px !important;
}

.u-margin-18, .u-m-18 {
  margin: 18px !important;
}

.u-padding-18, .u-p-18 {
  padding: 18px !important;
}

.u-m-l-18 {
  margin-left: 18px !important;
}

.u-p-l-18 {
  padding-left: 18px !important;
}

.u-margin-left-18 {
  margin-left: 18px !important;
}

.u-padding-left-18 {
  padding-left: 18px !important;
}

.u-m-t-18 {
  margin-top: 18px !important;
}

.u-p-t-18 {
  padding-top: 18px !important;
}

.u-margin-top-18 {
  margin-top: 18px !important;
}

.u-padding-top-18 {
  padding-top: 18px !important;
}

.u-m-r-18 {
  margin-right: 18px !important;
}

.u-p-r-18 {
  padding-right: 18px !important;
}

.u-margin-right-18 {
  margin-right: 18px !important;
}

.u-padding-right-18 {
  padding-right: 18px !important;
}

.u-m-b-18 {
  margin-bottom: 18px !important;
}

.u-p-b-18 {
  padding-bottom: 18px !important;
}

.u-margin-bottom-18 {
  margin-bottom: 18px !important;
}

.u-padding-bottom-18 {
  padding-bottom: 18px !important;
}

.u-margin-20, .u-m-20 {
  margin: 20px !important;
}

.u-padding-20, .u-p-20 {
  padding: 20px !important;
}

.u-m-l-20 {
  margin-left: 20px !important;
}

.u-p-l-20 {
  padding-left: 20px !important;
}

.u-margin-left-20 {
  margin-left: 20px !important;
}

.u-padding-left-20 {
  padding-left: 20px !important;
}

.u-m-t-20 {
  margin-top: 20px !important;
}

.u-p-t-20 {
  padding-top: 20px !important;
}

.u-margin-top-20 {
  margin-top: 20px !important;
}

.u-padding-top-20 {
  padding-top: 20px !important;
}

.u-m-r-20 {
  margin-right: 20px !important;
}

.u-p-r-20 {
  padding-right: 20px !important;
}

.u-margin-right-20 {
  margin-right: 20px !important;
}

.u-padding-right-20 {
  padding-right: 20px !important;
}

.u-m-b-20 {
  margin-bottom: 20px !important;
}

.u-p-b-20 {
  padding-bottom: 20px !important;
}

.u-margin-bottom-20 {
  margin-bottom: 20px !important;
}

.u-padding-bottom-20 {
  padding-bottom: 20px !important;
}

.u-margin-22, .u-m-22 {
  margin: 22px !important;
}

.u-padding-22, .u-p-22 {
  padding: 22px !important;
}

.u-m-l-22 {
  margin-left: 22px !important;
}

.u-p-l-22 {
  padding-left: 22px !important;
}

.u-margin-left-22 {
  margin-left: 22px !important;
}

.u-padding-left-22 {
  padding-left: 22px !important;
}

.u-m-t-22 {
  margin-top: 22px !important;
}

.u-p-t-22 {
  padding-top: 22px !important;
}

.u-margin-top-22 {
  margin-top: 22px !important;
}

.u-padding-top-22 {
  padding-top: 22px !important;
}

.u-m-r-22 {
  margin-right: 22px !important;
}

.u-p-r-22 {
  padding-right: 22px !important;
}

.u-margin-right-22 {
  margin-right: 22px !important;
}

.u-padding-right-22 {
  padding-right: 22px !important;
}

.u-m-b-22 {
  margin-bottom: 22px !important;
}

.u-p-b-22 {
  padding-bottom: 22px !important;
}

.u-margin-bottom-22 {
  margin-bottom: 22px !important;
}

.u-padding-bottom-22 {
  padding-bottom: 22px !important;
}

.u-margin-24, .u-m-24 {
  margin: 24px !important;
}

.u-padding-24, .u-p-24 {
  padding: 24px !important;
}

.u-m-l-24 {
  margin-left: 24px !important;
}

.u-p-l-24 {
  padding-left: 24px !important;
}

.u-margin-left-24 {
  margin-left: 24px !important;
}

.u-padding-left-24 {
  padding-left: 24px !important;
}

.u-m-t-24 {
  margin-top: 24px !important;
}

.u-p-t-24 {
  padding-top: 24px !important;
}

.u-margin-top-24 {
  margin-top: 24px !important;
}

.u-padding-top-24 {
  padding-top: 24px !important;
}

.u-m-r-24 {
  margin-right: 24px !important;
}

.u-p-r-24 {
  padding-right: 24px !important;
}

.u-margin-right-24 {
  margin-right: 24px !important;
}

.u-padding-right-24 {
  padding-right: 24px !important;
}

.u-m-b-24 {
  margin-bottom: 24px !important;
}

.u-p-b-24 {
  padding-bottom: 24px !important;
}

.u-margin-bottom-24 {
  margin-bottom: 24px !important;
}

.u-padding-bottom-24 {
  padding-bottom: 24px !important;
}

.u-margin-25, .u-m-25 {
  margin: 25px !important;
}

.u-padding-25, .u-p-25 {
  padding: 25px !important;
}

.u-m-l-25 {
  margin-left: 25px !important;
}

.u-p-l-25 {
  padding-left: 25px !important;
}

.u-margin-left-25 {
  margin-left: 25px !important;
}

.u-padding-left-25 {
  padding-left: 25px !important;
}

.u-m-t-25 {
  margin-top: 25px !important;
}

.u-p-t-25 {
  padding-top: 25px !important;
}

.u-margin-top-25 {
  margin-top: 25px !important;
}

.u-padding-top-25 {
  padding-top: 25px !important;
}

.u-m-r-25 {
  margin-right: 25px !important;
}

.u-p-r-25 {
  padding-right: 25px !important;
}

.u-margin-right-25 {
  margin-right: 25px !important;
}

.u-padding-right-25 {
  padding-right: 25px !important;
}

.u-m-b-25 {
  margin-bottom: 25px !important;
}

.u-p-b-25 {
  padding-bottom: 25px !important;
}

.u-margin-bottom-25 {
  margin-bottom: 25px !important;
}

.u-padding-bottom-25 {
  padding-bottom: 25px !important;
}

.u-margin-26, .u-m-26 {
  margin: 26px !important;
}

.u-padding-26, .u-p-26 {
  padding: 26px !important;
}

.u-m-l-26 {
  margin-left: 26px !important;
}

.u-p-l-26 {
  padding-left: 26px !important;
}

.u-margin-left-26 {
  margin-left: 26px !important;
}

.u-padding-left-26 {
  padding-left: 26px !important;
}

.u-m-t-26 {
  margin-top: 26px !important;
}

.u-p-t-26 {
  padding-top: 26px !important;
}

.u-margin-top-26 {
  margin-top: 26px !important;
}

.u-padding-top-26 {
  padding-top: 26px !important;
}

.u-m-r-26 {
  margin-right: 26px !important;
}

.u-p-r-26 {
  padding-right: 26px !important;
}

.u-margin-right-26 {
  margin-right: 26px !important;
}

.u-padding-right-26 {
  padding-right: 26px !important;
}

.u-m-b-26 {
  margin-bottom: 26px !important;
}

.u-p-b-26 {
  padding-bottom: 26px !important;
}

.u-margin-bottom-26 {
  margin-bottom: 26px !important;
}

.u-padding-bottom-26 {
  padding-bottom: 26px !important;
}

.u-margin-28, .u-m-28 {
  margin: 28px !important;
}

.u-padding-28, .u-p-28 {
  padding: 28px !important;
}

.u-m-l-28 {
  margin-left: 28px !important;
}

.u-p-l-28 {
  padding-left: 28px !important;
}

.u-margin-left-28 {
  margin-left: 28px !important;
}

.u-padding-left-28 {
  padding-left: 28px !important;
}

.u-m-t-28 {
  margin-top: 28px !important;
}

.u-p-t-28 {
  padding-top: 28px !important;
}

.u-margin-top-28 {
  margin-top: 28px !important;
}

.u-padding-top-28 {
  padding-top: 28px !important;
}

.u-m-r-28 {
  margin-right: 28px !important;
}

.u-p-r-28 {
  padding-right: 28px !important;
}

.u-margin-right-28 {
  margin-right: 28px !important;
}

.u-padding-right-28 {
  padding-right: 28px !important;
}

.u-m-b-28 {
  margin-bottom: 28px !important;
}

.u-p-b-28 {
  padding-bottom: 28px !important;
}

.u-margin-bottom-28 {
  margin-bottom: 28px !important;
}

.u-padding-bottom-28 {
  padding-bottom: 28px !important;
}

.u-margin-30, .u-m-30 {
  margin: 30px !important;
}

.u-padding-30, .u-p-30 {
  padding: 30px !important;
}

.u-m-l-30 {
  margin-left: 30px !important;
}

.u-p-l-30 {
  padding-left: 30px !important;
}

.u-margin-left-30 {
  margin-left: 30px !important;
}

.u-padding-left-30 {
  padding-left: 30px !important;
}

.u-m-t-30 {
  margin-top: 30px !important;
}

.u-p-t-30 {
  padding-top: 30px !important;
}

.u-margin-top-30 {
  margin-top: 30px !important;
}

.u-padding-top-30 {
  padding-top: 30px !important;
}

.u-m-r-30 {
  margin-right: 30px !important;
}

.u-p-r-30 {
  padding-right: 30px !important;
}

.u-margin-right-30 {
  margin-right: 30px !important;
}

.u-padding-right-30 {
  padding-right: 30px !important;
}

.u-m-b-30 {
  margin-bottom: 30px !important;
}

.u-p-b-30 {
  padding-bottom: 30px !important;
}

.u-margin-bottom-30 {
  margin-bottom: 30px !important;
}

.u-padding-bottom-30 {
  padding-bottom: 30px !important;
}

.u-margin-32, .u-m-32 {
  margin: 32px !important;
}

.u-padding-32, .u-p-32 {
  padding: 32px !important;
}

.u-m-l-32 {
  margin-left: 32px !important;
}

.u-p-l-32 {
  padding-left: 32px !important;
}

.u-margin-left-32 {
  margin-left: 32px !important;
}

.u-padding-left-32 {
  padding-left: 32px !important;
}

.u-m-t-32 {
  margin-top: 32px !important;
}

.u-p-t-32 {
  padding-top: 32px !important;
}

.u-margin-top-32 {
  margin-top: 32px !important;
}

.u-padding-top-32 {
  padding-top: 32px !important;
}

.u-m-r-32 {
  margin-right: 32px !important;
}

.u-p-r-32 {
  padding-right: 32px !important;
}

.u-margin-right-32 {
  margin-right: 32px !important;
}

.u-padding-right-32 {
  padding-right: 32px !important;
}

.u-m-b-32 {
  margin-bottom: 32px !important;
}

.u-p-b-32 {
  padding-bottom: 32px !important;
}

.u-margin-bottom-32 {
  margin-bottom: 32px !important;
}

.u-padding-bottom-32 {
  padding-bottom: 32px !important;
}

.u-margin-34, .u-m-34 {
  margin: 34px !important;
}

.u-padding-34, .u-p-34 {
  padding: 34px !important;
}

.u-m-l-34 {
  margin-left: 34px !important;
}

.u-p-l-34 {
  padding-left: 34px !important;
}

.u-margin-left-34 {
  margin-left: 34px !important;
}

.u-padding-left-34 {
  padding-left: 34px !important;
}

.u-m-t-34 {
  margin-top: 34px !important;
}

.u-p-t-34 {
  padding-top: 34px !important;
}

.u-margin-top-34 {
  margin-top: 34px !important;
}

.u-padding-top-34 {
  padding-top: 34px !important;
}

.u-m-r-34 {
  margin-right: 34px !important;
}

.u-p-r-34 {
  padding-right: 34px !important;
}

.u-margin-right-34 {
  margin-right: 34px !important;
}

.u-padding-right-34 {
  padding-right: 34px !important;
}

.u-m-b-34 {
  margin-bottom: 34px !important;
}

.u-p-b-34 {
  padding-bottom: 34px !important;
}

.u-margin-bottom-34 {
  margin-bottom: 34px !important;
}

.u-padding-bottom-34 {
  padding-bottom: 34px !important;
}

.u-margin-35, .u-m-35 {
  margin: 35px !important;
}

.u-padding-35, .u-p-35 {
  padding: 35px !important;
}

.u-m-l-35 {
  margin-left: 35px !important;
}

.u-p-l-35 {
  padding-left: 35px !important;
}

.u-margin-left-35 {
  margin-left: 35px !important;
}

.u-padding-left-35 {
  padding-left: 35px !important;
}

.u-m-t-35 {
  margin-top: 35px !important;
}

.u-p-t-35 {
  padding-top: 35px !important;
}

.u-margin-top-35 {
  margin-top: 35px !important;
}

.u-padding-top-35 {
  padding-top: 35px !important;
}

.u-m-r-35 {
  margin-right: 35px !important;
}

.u-p-r-35 {
  padding-right: 35px !important;
}

.u-margin-right-35 {
  margin-right: 35px !important;
}

.u-padding-right-35 {
  padding-right: 35px !important;
}

.u-m-b-35 {
  margin-bottom: 35px !important;
}

.u-p-b-35 {
  padding-bottom: 35px !important;
}

.u-margin-bottom-35 {
  margin-bottom: 35px !important;
}

.u-padding-bottom-35 {
  padding-bottom: 35px !important;
}

.u-margin-36, .u-m-36 {
  margin: 36px !important;
}

.u-padding-36, .u-p-36 {
  padding: 36px !important;
}

.u-m-l-36 {
  margin-left: 36px !important;
}

.u-p-l-36 {
  padding-left: 36px !important;
}

.u-margin-left-36 {
  margin-left: 36px !important;
}

.u-padding-left-36 {
  padding-left: 36px !important;
}

.u-m-t-36 {
  margin-top: 36px !important;
}

.u-p-t-36 {
  padding-top: 36px !important;
}

.u-margin-top-36 {
  margin-top: 36px !important;
}

.u-padding-top-36 {
  padding-top: 36px !important;
}

.u-m-r-36 {
  margin-right: 36px !important;
}

.u-p-r-36 {
  padding-right: 36px !important;
}

.u-margin-right-36 {
  margin-right: 36px !important;
}

.u-padding-right-36 {
  padding-right: 36px !important;
}

.u-m-b-36 {
  margin-bottom: 36px !important;
}

.u-p-b-36 {
  padding-bottom: 36px !important;
}

.u-margin-bottom-36 {
  margin-bottom: 36px !important;
}

.u-padding-bottom-36 {
  padding-bottom: 36px !important;
}

.u-margin-38, .u-m-38 {
  margin: 38px !important;
}

.u-padding-38, .u-p-38 {
  padding: 38px !important;
}

.u-m-l-38 {
  margin-left: 38px !important;
}

.u-p-l-38 {
  padding-left: 38px !important;
}

.u-margin-left-38 {
  margin-left: 38px !important;
}

.u-padding-left-38 {
  padding-left: 38px !important;
}

.u-m-t-38 {
  margin-top: 38px !important;
}

.u-p-t-38 {
  padding-top: 38px !important;
}

.u-margin-top-38 {
  margin-top: 38px !important;
}

.u-padding-top-38 {
  padding-top: 38px !important;
}

.u-m-r-38 {
  margin-right: 38px !important;
}

.u-p-r-38 {
  padding-right: 38px !important;
}

.u-margin-right-38 {
  margin-right: 38px !important;
}

.u-padding-right-38 {
  padding-right: 38px !important;
}

.u-m-b-38 {
  margin-bottom: 38px !important;
}

.u-p-b-38 {
  padding-bottom: 38px !important;
}

.u-margin-bottom-38 {
  margin-bottom: 38px !important;
}

.u-padding-bottom-38 {
  padding-bottom: 38px !important;
}

.u-margin-40, .u-m-40 {
  margin: 40px !important;
}

.u-padding-40, .u-p-40 {
  padding: 40px !important;
}

.u-m-l-40 {
  margin-left: 40px !important;
}

.u-p-l-40 {
  padding-left: 40px !important;
}

.u-margin-left-40 {
  margin-left: 40px !important;
}

.u-padding-left-40 {
  padding-left: 40px !important;
}

.u-m-t-40 {
  margin-top: 40px !important;
}

.u-p-t-40 {
  padding-top: 40px !important;
}

.u-margin-top-40 {
  margin-top: 40px !important;
}

.u-padding-top-40 {
  padding-top: 40px !important;
}

.u-m-r-40 {
  margin-right: 40px !important;
}

.u-p-r-40 {
  padding-right: 40px !important;
}

.u-margin-right-40 {
  margin-right: 40px !important;
}

.u-padding-right-40 {
  padding-right: 40px !important;
}

.u-m-b-40 {
  margin-bottom: 40px !important;
}

.u-p-b-40 {
  padding-bottom: 40px !important;
}

.u-margin-bottom-40 {
  margin-bottom: 40px !important;
}

.u-padding-bottom-40 {
  padding-bottom: 40px !important;
}

.u-margin-42, .u-m-42 {
  margin: 42px !important;
}

.u-padding-42, .u-p-42 {
  padding: 42px !important;
}

.u-m-l-42 {
  margin-left: 42px !important;
}

.u-p-l-42 {
  padding-left: 42px !important;
}

.u-margin-left-42 {
  margin-left: 42px !important;
}

.u-padding-left-42 {
  padding-left: 42px !important;
}

.u-m-t-42 {
  margin-top: 42px !important;
}

.u-p-t-42 {
  padding-top: 42px !important;
}

.u-margin-top-42 {
  margin-top: 42px !important;
}

.u-padding-top-42 {
  padding-top: 42px !important;
}

.u-m-r-42 {
  margin-right: 42px !important;
}

.u-p-r-42 {
  padding-right: 42px !important;
}

.u-margin-right-42 {
  margin-right: 42px !important;
}

.u-padding-right-42 {
  padding-right: 42px !important;
}

.u-m-b-42 {
  margin-bottom: 42px !important;
}

.u-p-b-42 {
  padding-bottom: 42px !important;
}

.u-margin-bottom-42 {
  margin-bottom: 42px !important;
}

.u-padding-bottom-42 {
  padding-bottom: 42px !important;
}

.u-margin-44, .u-m-44 {
  margin: 44px !important;
}

.u-padding-44, .u-p-44 {
  padding: 44px !important;
}

.u-m-l-44 {
  margin-left: 44px !important;
}

.u-p-l-44 {
  padding-left: 44px !important;
}

.u-margin-left-44 {
  margin-left: 44px !important;
}

.u-padding-left-44 {
  padding-left: 44px !important;
}

.u-m-t-44 {
  margin-top: 44px !important;
}

.u-p-t-44 {
  padding-top: 44px !important;
}

.u-margin-top-44 {
  margin-top: 44px !important;
}

.u-padding-top-44 {
  padding-top: 44px !important;
}

.u-m-r-44 {
  margin-right: 44px !important;
}

.u-p-r-44 {
  padding-right: 44px !important;
}

.u-margin-right-44 {
  margin-right: 44px !important;
}

.u-padding-right-44 {
  padding-right: 44px !important;
}

.u-m-b-44 {
  margin-bottom: 44px !important;
}

.u-p-b-44 {
  padding-bottom: 44px !important;
}

.u-margin-bottom-44 {
  margin-bottom: 44px !important;
}

.u-padding-bottom-44 {
  padding-bottom: 44px !important;
}

.u-margin-45, .u-m-45 {
  margin: 45px !important;
}

.u-padding-45, .u-p-45 {
  padding: 45px !important;
}

.u-m-l-45 {
  margin-left: 45px !important;
}

.u-p-l-45 {
  padding-left: 45px !important;
}

.u-margin-left-45 {
  margin-left: 45px !important;
}

.u-padding-left-45 {
  padding-left: 45px !important;
}

.u-m-t-45 {
  margin-top: 45px !important;
}

.u-p-t-45 {
  padding-top: 45px !important;
}

.u-margin-top-45 {
  margin-top: 45px !important;
}

.u-padding-top-45 {
  padding-top: 45px !important;
}

.u-m-r-45 {
  margin-right: 45px !important;
}

.u-p-r-45 {
  padding-right: 45px !important;
}

.u-margin-right-45 {
  margin-right: 45px !important;
}

.u-padding-right-45 {
  padding-right: 45px !important;
}

.u-m-b-45 {
  margin-bottom: 45px !important;
}

.u-p-b-45 {
  padding-bottom: 45px !important;
}

.u-margin-bottom-45 {
  margin-bottom: 45px !important;
}

.u-padding-bottom-45 {
  padding-bottom: 45px !important;
}

.u-margin-46, .u-m-46 {
  margin: 46px !important;
}

.u-padding-46, .u-p-46 {
  padding: 46px !important;
}

.u-m-l-46 {
  margin-left: 46px !important;
}

.u-p-l-46 {
  padding-left: 46px !important;
}

.u-margin-left-46 {
  margin-left: 46px !important;
}

.u-padding-left-46 {
  padding-left: 46px !important;
}

.u-m-t-46 {
  margin-top: 46px !important;
}

.u-p-t-46 {
  padding-top: 46px !important;
}

.u-margin-top-46 {
  margin-top: 46px !important;
}

.u-padding-top-46 {
  padding-top: 46px !important;
}

.u-m-r-46 {
  margin-right: 46px !important;
}

.u-p-r-46 {
  padding-right: 46px !important;
}

.u-margin-right-46 {
  margin-right: 46px !important;
}

.u-padding-right-46 {
  padding-right: 46px !important;
}

.u-m-b-46 {
  margin-bottom: 46px !important;
}

.u-p-b-46 {
  padding-bottom: 46px !important;
}

.u-margin-bottom-46 {
  margin-bottom: 46px !important;
}

.u-padding-bottom-46 {
  padding-bottom: 46px !important;
}

.u-margin-48, .u-m-48 {
  margin: 48px !important;
}

.u-padding-48, .u-p-48 {
  padding: 48px !important;
}

.u-m-l-48 {
  margin-left: 48px !important;
}

.u-p-l-48 {
  padding-left: 48px !important;
}

.u-margin-left-48 {
  margin-left: 48px !important;
}

.u-padding-left-48 {
  padding-left: 48px !important;
}

.u-m-t-48 {
  margin-top: 48px !important;
}

.u-p-t-48 {
  padding-top: 48px !important;
}

.u-margin-top-48 {
  margin-top: 48px !important;
}

.u-padding-top-48 {
  padding-top: 48px !important;
}

.u-m-r-48 {
  margin-right: 48px !important;
}

.u-p-r-48 {
  padding-right: 48px !important;
}

.u-margin-right-48 {
  margin-right: 48px !important;
}

.u-padding-right-48 {
  padding-right: 48px !important;
}

.u-m-b-48 {
  margin-bottom: 48px !important;
}

.u-p-b-48 {
  padding-bottom: 48px !important;
}

.u-margin-bottom-48 {
  margin-bottom: 48px !important;
}

.u-padding-bottom-48 {
  padding-bottom: 48px !important;
}

.u-margin-50, .u-m-50 {
  margin: 50px !important;
}

.u-padding-50, .u-p-50 {
  padding: 50px !important;
}

.u-m-l-50 {
  margin-left: 50px !important;
}

.u-p-l-50 {
  padding-left: 50px !important;
}

.u-margin-left-50 {
  margin-left: 50px !important;
}

.u-padding-left-50 {
  padding-left: 50px !important;
}

.u-m-t-50 {
  margin-top: 50px !important;
}

.u-p-t-50 {
  padding-top: 50px !important;
}

.u-margin-top-50 {
  margin-top: 50px !important;
}

.u-padding-top-50 {
  padding-top: 50px !important;
}

.u-m-r-50 {
  margin-right: 50px !important;
}

.u-p-r-50 {
  padding-right: 50px !important;
}

.u-margin-right-50 {
  margin-right: 50px !important;
}

.u-padding-right-50 {
  padding-right: 50px !important;
}

.u-m-b-50 {
  margin-bottom: 50px !important;
}

.u-p-b-50 {
  padding-bottom: 50px !important;
}

.u-margin-bottom-50 {
  margin-bottom: 50px !important;
}

.u-padding-bottom-50 {
  padding-bottom: 50px !important;
}

.font_blod_300 {
  font-weight: 300;
}

.font_blod_700 {
  font-weight: 700;
}

.line2 {
  line-height: 2;
}

.lin1_5 {
  line-height: 1.5;
}

.u-relative,
.u-rela {
  position: relative;
}

.u-absolute,
.u-abso {
  position: absolute;
}

.u-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-nowrap {
  flex-wrap: nowrap;
}

.u-col-center {
  align-items: center;
}

.u-col-top {
  align-items: flex-start;
}

.u-col-bottom {
  align-items: flex-end;
}

.u-row-center {
  justify-content: center;
}

.u-row-left {
  justify-content: flex-start;
}

.u-row-right {
  justify-content: flex-end;
}

.u-row-between {
  justify-content: space-between;
}

.u-row-around {
  justify-content: space-around;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-flex-col {
  display: flex;
  flex-direction: column;
}

.u-flex-0 {
  flex: 0;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-2 {
  flex: 2;
}

.u-flex-3 {
  flex: 3;
}

.u-flex-4 {
  flex: 4;
}

.u-flex-5 {
  flex: 5;
}

.u-flex-6 {
  flex: 6;
}

.u-flex-7 {
  flex: 7;
}

.u-flex-8 {
  flex: 8;
}

.u-flex-9 {
  flex: 9;
}

.u-flex-10 {
  flex: 10;
}

.u-flex-11 {
  flex: 11;
}

.u-flex-12 {
  flex: 12;
}

.u-reset-nvue {
  flex-direction: row;
  align-items: center;
}

html {
  font-size: 10vw;
}

@media screen and (min-width: 1800px) {
  html .bz_wrapper_content {
    max-width: 1780px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 144px;
  }
}

@media screen and (max-width: 1366px) {
  html {
    font-size: 136.6px;
  }
}

@media screen and (max-width: 1200px) {
  html .business_banner_con {
    padding-left: 12px;
  }

  html .service_wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 120px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 102.4px;
  }
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 100px;
  }
}

.bz_qybz .mobileshow, .bz_zyzj .mobileshow, .bz_td .mobileshow {
  display: none;
}

.bz_wrapper_content {
  max-width: 1200px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
}

.service_wrapper {
  padding-left: 100px;
  padding-right: 100px;
}

.m_business_banner, .mobiletab {
  display: none;
}

.business_banner {
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
}

.business_banner img {
  width: 100%;
  height: 100%;
}

.business_banner_main, .banner_main {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.business_banner_con {
  padding-left: 100px;
  height: 100%;
  overflow: hidden;
}

.business_banner_con .banner_right, .business_banner_con .banner_right img {
  width: auto;
  height: 100%;
}

.banner_left .slogancon {
  margin-bottom: 30px;
}

.banner_left .slogan {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 0px;
  color: #fff;
}

.banner_left .sloganDiv {
  width: 637px
}

#business_banner_con .banner_left .slogan {
  text-shadow: 0px;
}

.shadow_ffffff {
  text-shadow: 5px 5px 5px #ffffff !important;
}

.sloganbutton {
  font-size: 20px;
  line-height: 1.4;
  padding: 13px 26px;
  background: #ff2c9c;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.sloganbutton:hover {
  background: #fa5f9b;
  border-color: #fa5f9b;
}

.boder_bottom {
  border-bottom: solid 1px #030f3b;
}

.service_title {
  padding-top: 30px;
  padding-bottom: 30px;
}

.flex-con-img img {
  width: 100%;
  height: auto;
}

.flex-con-img2 img {
  width: 90%;
  height: auto;
}

.bz_about_text {
  width: 65%;
}

.flex-con-img {
  width: 65%;
}

.jionuscon {
  width: 100%;
  background: #dedede;
  margin-top: 30px;
}

.jionuscontent {
  padding: 48px;
}

.indent2 {
  text-indent: 2em;
}

.contactusform .labeltext span {
  color: #ff2c9c;
}

.contactusform input {
  height: 34px;
  width: 80%;
  -webkit-appearance: none;
  color: #474747;
  border: solid 1px #474747;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.contactusform textarea {
  width: 80%;
  -webkit-appearance: none;
  color: #474747;
  border: solid 1px #474747;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  outline: none;
}

.customer {
  flex-flow: row wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.customerlist {
  flex-basis: 25%;
  max-width: 25%;
}

.customercon, .customercontext {
  padding: 0px 0.125rem;
}

.customerlist img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.moredetail {
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: #19202d99;
  border-radius: 5px;
  opacity: 0;
  cursor: pointer;
}

.imgscalecon {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.imgscale {
  transition: all 0.5s;
  　　-moz-transition: all 0.5s;
  /* Firefox 4 */
  　　-webkit-transition: all 0.5s;
  /* Safari 和 Chrome */
  　　-o-transition: all 0.5s;
  /* Opera */
  transition: transform .2s ease;
}

.imgscale:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.imgscale img {
  width: 100%;
}

.moredetail:hover {
  opacity: 1;
  transition: all 1s linear;
}

.seemore {
  height: 100%;
}

.seemore .buttonmore {
  border: solid 1px #f7f7f7;
  border-radius: 5px;
  color: #ffffff;
  padding: 0.078125rem 0.1041666667rem;
}

.navtab {
  width: 1000px;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  background: #0077ea;
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  color: #ffffff;
  cursor: pointer;
}

.navtab div:not(.active) {
  color: #ffffff;
}

.navtab div:not(.active) a {
  color: #ffffff;
}

.navtab .active {
  color: #0077ea;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  box-shadow: 0px -2px 10px #cdcdcd;
}

.navtab .active a {
  color: #0077ea;
}

.casecontainer {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.caselist {
  width: 25%;
  flex-basis: 25%;
  padding: 0px 12px 0px;
  overflow: hidden;
}

.listcontainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 61.8453%;
  overflow: hidden;
  transform: translateZ(0);
  border-radius: 5px;
  margin-bottom: 35px;
  background-color: #ffffff;
}

.listcontainer:hover {
  box-shadow: 0 0 4px 0 #00000014, 0 2px 4px 0 #0000001f;
  border-radius: 5px;
}

.listcontainer:hover .text {
  transform: translate(0);
  transition: transform .3s ease-out;
}

.listcontainer img {
  position: absolute;
  width: 100%;
  border-radius: 5px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: transform .2s ease;
}

.listcontainer:hover img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1);
  -ms-transform: scale3d(1.1, 1.1, 1);
  -o-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

.listcontainer .text {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  color: #474747;
  line-height: 1.5;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
  background-color: #ffffff;
  padding: 10px;
}

.listcontainer .text p {
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 1220px) {
  .business_banner_con {
    padding-left: 50px;
  }

  .banner_left .slogan {
    font-size: 40px;
  }
}

@media screen and (max-width: 992px) {
  .business_banner {
    display: none;
  }

  .m_business_banner {
    display: flex;
    width: 100%;
  }

  .m_business_banner img {
    width: 100%;
    height: auto;
  }

  .m_business_banner .banner_left {
    height: 100%;
    padding-left: 20px;
  }

  .m_business_banner .banner_left .slogancon {
    margin-bottom: 20px;
  }

  .m_business_banner .banner_left .slogan {
    font-size: 36px;
    font-style: normal;
    margin-bottom: 0px;
    line-height: 2;
  }

  .m_business_banner .banner_left .sloganbutton {
    padding: 10px 26px;
    margin: 0px 0px;
    font-size: 16px;
  }

  .bz_td {
    flex-wrap: wrap;
  }

  .bz_td .bz_tdlist {
    flex-basis: 50%;
  }

  .bz_td .bz_tdlist:nth-child(1), .bz_td .bz_tdlist:nth-child(2) {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .m_business_banner .banner_left .slogan {
    font-size: 24px;
    margin-bottom: 0px;
    line-height: 1.5;
  }

  .m_business_banner .banner_left .sloganbutton {
    padding: 10px 26px;
    margin: 0px 0px;
    font-size: 14px;
  }

  .service_title {
    font-size: 24px;
  }

  .bz_td .bz_tdlist {
    flex-basis: 100%;
  }

  .commonad .commonadcontent .u-font-30, .commonad .commonadcontent2 .u-font-30 {
    font-size: 20px;
  }
}

@media screen and (max-width: 576px) {
  .bz_qybz {
    flex-direction: column;
    align-items: baseline;
    padding-top: 0px !important;
  }

  .bz_zyzj {
    flex-direction: column-reverse;
    align-items: baseline;
    padding-top: 0px !important;
  }

  .commonad .commonadcontent {
    flex-direction: column;
    padding: 5px 0px 15px 0px !important;
  }

  .commonad .commonadcontent2 {
    padding: 15px 0px 15px 0px !important;
  }

  .commonad .commonadcontentlist .u-font-18, .commonad .commonadcontentlist2 .u-font-18 {
    font-size: 12px;
    line-height: 1;
  }

  .commonad .commonadcontentlist p:nth-of-type(1), .commonad .commonadcontentlist p:nth-of-type(2), .commonad .commonadcontentlist2 p:nth-of-type(2) {
    display: none;
  }

  .commonad .commonadcontentlist p:nth-of-type(3) {
    padding-bottom: 15px;
  }

  .commonad .sloganbutton {
    padding: 8px 20px;
    font-size: 14px;
    margin: 10px 0px;
  }

  .casecontainer .caselist {
    width: 50%;
    flex-basis: 50%;
  }
}

@media screen and (max-width: 416px) {
  .service_title {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .bz_td .bz_tdlist {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .bz_td .bz_tdlist img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .bz_td .bz_tdlist .u-font-16 {
    font-size: 14px;
    flex-basis: 100%;
    margin-top: 8px;
  }

  .bz_td .bz_tdlist .u-font-24 {
    font-size: 18px;
    font-weight: 700;
  }

  .bz_qybz .u-font-18, .bz_zyzj .u-font-18 {
    font-size: 14px;
  }

  .bz_qybz .line2, .bz_zyzj .line2 {
    line-height: 1.8;
  }

  .bz_qybz .pcshow, .bz_zyzj .pcshow, .bz_td .pcshow {
    display: none;
  }

  .bz_qybz .mobileshow, .bz_zyzj .mobileshow, .bz_td .mobileshow {
    display: block;
  }

  .small-title {
    font-size: 12px;
    font-weight: normal;
  }

  .casecontainer {
    width: auto;
    margin: 0px -8px 0px -8px;
  }

  .casecontainer .caselist {
    padding: 0px 8px 0px;
  }

  .casecontainer .listcontainer {
    margin-bottom: 20px;
  }

  .commonad .commonadcontentlist .u-font-18, .commonad .commonadcontentlist2 .u-font-18 {
    font-size: 14px;
  }

  .lookmore {
    margin-top: 0px !important;
    margin-bottom: 15px !important;
  }

  .lookmore .sloganbutton {
    font-size: 16px;
    line-height: 1;
    padding: 10px 20px;
  }

  .listcontainer .text {
    transform: translateY(0);
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .listcontainer .text .u-font-18 {
    font-size: 16px;
  }

  .bz_zyzj .iconimg, .bz_qybz .iconimg {
    width: 40px;
  }

  .bz_about_text, .bz_about_img {
    width: 100%;
  }

  .bz_about_text {
    text-align: justify;
  }

  .bz_about_img {
    margin-top: 20px;
  }

  .bz_about_text .u-font-40 {
    font-size: 24px;
  }

  .contactusformlist {
    flex-direction: column;
    align-items: flex-start;
  }

  .contactusformlist .u-flex-1 {
    width: 100%;
  }

  .contactusform .contactusformlist input, .contactusform textarea {
    width: 100%;
  }

  .contactusform textarea {
    height: 100px;
  }

  .contactusform.sloganbutton {
    padding: 10px 26px;
    margin: 0px 0px;
    font-size: 14px;
  }

  .flex-con-img {
    width: 100%;
  }

  .bz_zyzj .u-font-20, .bz_qybz .u-font-20 {
    font-size: 18px;
  }

  .bz_zyzj .u-font-16, .bz_qybz .u-font-16 {
    font-size: 14px;
  }

  .flex-start {
    align-items: flex-start;
  }

  .contactusform .labeltext {
    font-size: 14px;
  }

  .mobiletab {
    display: block;
    width: 96%;
    margin: 15px auto 0px auto;
  }

  .mobiletab .navtab {
    width: 100%;
    position: unset;
    transform: translate(0);
  }

  .mobiletab .navtab .u-flex-1 {
    flex-basis: 50%;
  }

  .mobiletab .navtab .u-p-t-15 {
    padding-top: 12px !important;
  }

  .mobiletab .navtab .u-p-b-15 {
    padding-bottom: 12px !important;
  }

  .mobiletab .navtab .u-font-22 {
    font-size: 14px;
  }
}

@media screen and (max-width: 378px) {
  .m_business_banner .banner_left .slogan {
    font-size: 18px;
  }

  .m_business_banner .banner_left .sloganbutton {
    padding: 8px 20px;
    font-size: 14px;
  }

  .service_title {
    font-size: 20px;
  }

  .casecontainer .caselist {
    width: 100%;
    flex-basis: 100%;
  }

  .commonad .commonadcontentlist .u-font-18 {
    font-size: 12px;
  }

  .listcontainer .text .u-font-18 {
    font-size: 18px;
  }

  .listcontainer .text .u-font-16 {
    font-size: 16px;
  }

  .bz_td .bz_tdlist img {
    width: 30px;
    height: 30px;
  }

  .bz_zyzj .iconimg, .bz_qybz .iconimg {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 322px) {
  .m_business_banner .banner_left .slogan {
    font-size: 16px;
  }

  .m_business_banner .banner_left .sloganbutton {
    padding: 8px 20px;
    font-size: 12px;
  }

  .service_title {
    font-size: 18px;
  }

  .bz_zyzj .u-font-20, .bz_qybz .u-font-20 {
    font-size: 16px;
  }

  .bz_zyzj .u-font-16, .bz_qybz .u-font-16 {
    font-size: 12px;
  }
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
}

body {
  color: #474747;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  border: none;
}

img {
  border: 0px;
}

img,
input {
  outline-style: none;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

/*.curspointer{*/
/*cursor: pointer;*/
/*}*/
.fl {
  float: left;
}

.fr {
  float: right;
}

/*.curspointer{*/
/*cursor: pointer;*/
/*}*/

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button
}

@-webkit-keyframes bz_fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bz_fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bz_fadeIn {
  animation-name: bz_fadeIn;
  -webkit-animation-name: bz_fadeIn;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

picture {
  width: 100%;
}
</style>
